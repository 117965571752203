/* import __COLOCATED_TEMPLATE__ from './template-simple-card.hbs'; */
/* RESPONSIBLE TEAM: team-fin-nux */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  template: any;
  onClick: () => void;
}

interface Signature {
  Args: Args;
}

const TemplateSimpleCard = templateOnlyComponent<Signature>();
export default TemplateSimpleCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TemplateSimpleCard': typeof TemplateSimpleCard;
  }
}
