/* import __COLOCATED_TEMPLATE__ from './fin-workflows-overview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { action } from '@ember/object';
import { Column, ColumnConfigs } from 'embercom/objects/operator/configuration/custom-bot';
import { tracked } from 'tracked-built-ins';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type IntlService from 'embercom/services/intl';
import type Template from 'embercom/models/template';
import type Store from '@ember-data/store';
import type { BotConfigTarget } from 'embercom/objects/operator/configuration/configuration';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import Ruleset from 'embercom/models/matching-system/ruleset';

const CHAT_TEMPLATES_MAP = [
  {
    id: '2014',
    title: 'fin-ai-agent.deploy.workflow-templates.fin-messenger.title',
    description: 'fin-ai-agent.deploy.workflow-templates.fin-messenger.description',
    icon: 'manual-message',
  },
  {
    id: '2015',
    title: 'fin-ai-agent.deploy.workflow-templates.triage-inbound.title',
    description: 'fin-ai-agent.deploy.workflow-templates.triage-inbound.description',
    icon: 'wave',
  },
  {
    id: '2018',
    title: 'fin-ai-agent.deploy.workflow-templates.outside-hours.title',
    description: 'fin-ai-agent.deploy.workflow-templates.outside-hours.description',
    icon: 'manual-message',
  },
  {
    id: '2016',
    title: 'fin-ai-agent.deploy.workflow-templates.social-channels.title',
    description: 'fin-ai-agent.deploy.workflow-templates.social-channels.description',
    icon: 'manual-message',
  },
];

const EMAIL_TEMPLATES_MAP = [
  {
    id: '2017',
    title: 'fin-ai-agent.deploy.workflow-templates.fin-email.title',
    description: 'fin-ai-agent.deploy.workflow-templates.fin-email.description',
    icon: 'manual-message',
  },
  {
    id: '2018',
    title: 'fin-ai-agent.deploy.workflow-templates.outside-hours.title',
    description: 'fin-ai-agent.deploy.workflow-templates.outside-hours.description',
    icon: 'manual-message',
  },
];
interface WrapperTemplate {
  id: string;
  title: string;
  description: string;
  template: Template | null;
}
interface Arguments {
  isLoading: boolean;
  pageSize: number;
  finWorkflowsLastPageHit: boolean;
  workflows: ContentWrapper[];
  loadMoreFinWorkflows: () => void;
  channelType: string;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

type TableSortState = {
  valuePath: string;
  direction: SortDirection;
};

export default class FinWorkflowsOverview extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare permissionsService: any;
  @tracked sortBy: TableSortState = { valuePath: '', direction: SortDirection.ASC };
  @tracked templates: WrapperTemplate[] = [];
  @tracked internalLoading = true;

  stateKey = 'state';

  constructor(owner: any, args: Arguments) {
    super(owner, args);
    this.loadTemplates();
  }

  readonly columnConfigs = new ColumnConfigs();
  statisticColumns = [{ valuePath: 'sentCount', key: statisticKeys.receipts }];

  get columnKeys(): (keyof ColumnConfigs)[] {
    return ['title', this.stateKey as keyof ColumnConfigs, 'lastUpdatedBy', 'sentCount'];
  }

  get columns() {
    return this.columnKeys.map((key) => {
      let col = new Column(this.intl, this.columnConfigs[key]);
      if (key === this.stateKey) {
        col.isSortable = true;
      }
      return col;
    });
  }

  get sortedData() {
    let key = this.sortBy.valuePath;

    return this.args.workflows.sort((a: any, b: any) => {
      let aVal = a[key] || a.contents?.firstObject?.[key];
      let bVal = b[key] || b.contents?.firstObject?.[key];

      if (aVal > bVal) {
        return this.sortDirection === SortDirection.ASC ? -1 : 1;
      } else if (aVal < bVal) {
        return this.sortDirection === SortDirection.ASC ? 1 : -1;
      }

      return 0; // return 0 when aVal and bVal are equal
    });
  }

  get sortDirection() {
    return this.sortBy.direction;
  }

  get isLoading() {
    return this.internalLoading || this.args.isLoading;
  }

  get templatesMap() {
    return this.args.channelType === 'email' ? EMAIL_TEMPLATES_MAP : CHAT_TEMPLATES_MAP;
  }

  get canCreateWorkflows() {
    return (
      this.permissionsService.currentAdminCan('can_create_and_edit_bots') &&
      this.appService.app.canUseFeature('workflows_core_billing_feature')
    );
  }

  @action
  async loadTemplates() {
    this.templates = this.templatesMap.map((t) => ({
      ...t,
      template: null,
    })) as WrapperTemplate[];
    (await this.store.findAll('template')).forEach((template) => {
      let index = this.templates.findIndex((t) => t.id === template.id);
      if (index !== -1) {
        this.templates[index].template = template;
      }
    });

    this.internalLoading = false;
  }

  @action
  onSortUpdate(sortBy: string) {
    let sort: TableSortState = {
      valuePath: sortBy,
      direction:
        this.sortBy.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
    };
    this.sortBy = sort;
  }

  @action
  async openWorkflowInNewTab(rulesetId: string) {
    safeWindowOpen(
      this.router.urlFor('apps.app.automation.workflows.edit', rulesetId, {
        queryParams: {
          mode: 'edit',
          visual: 'enabled',
        },
      }),
    );
  }

  @action
  async createWorkflow(template: WrapperTemplate) {
    if (!this.canCreateWorkflows) {
      // if the user is missing the permission we show the modal, if they just can't use the feature we dont do nothing
      if (this.appService.app.canUseFeature('workflows_core_billing_feature')) {
        this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
          'can_create_and_edit_bots',
        );
      }
      return;
    }
    let rulesetParams: {
      app_id: string;
      object_type: string | null;
      template_id?: string;
      role_predicate_group?: object;
      object_data?: object;
      match_behavior?: number;
    } = {
      app_id: this.appService.app.id,
      object_type: template.template?.objectType,
    };
    let selectedTarget = template.template?.ruleset?.rulesetLinks.firstObject.object.target;
    let botConfig = selectedTarget && CUSTOM_BOT_CONFIG[selectedTarget as BotConfigTarget];
    let { matchBehavior, rolePredicateGroup, botType, trigger } = botConfig;

    if (template.template?.id) {
      rulesetParams.template_id = template.template?.id;
    } else {
      rulesetParams.match_behavior = matchBehavior;
      rulesetParams.role_predicate_group = rolePredicateGroup;
      rulesetParams.object_data = {
        type: botType,
        trigger_type: trigger,
      };
    }

    let ruleset = await Ruleset.createForType(this.store, rulesetParams);
    if (!ruleset) {
      // in case of error trying to create the ruleset
      return;
    }
    if (this.args.channelType === 'email') {
      let link = ruleset.rulesetLinks.firstObject;
      link.object.targetChannels = ['email'];
    }

    this.router.transitionTo('apps.app.outbound.tour.edit', ruleset, {
      queryParams: {
        mode: 'edit',
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::FinWorkflowsOverview': typeof FinWorkflowsOverview;
  }
}
